import Step from "../../components/step/Step";
import {Form, Field, Formik} from 'formik';
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    Input,
    InputGroup,
    InputRightAddon,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select
} from "@chakra-ui/react";
import * as Yup from 'yup';
import {useNavigate} from "react-router-dom";
import useStore from "../../storage/DataStore";
import {FormattedMessage, useIntl} from "react-intl";

function createAgeRange(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

function Step12() {
    const intl = useIntl()
    let country = intl.locale;

    let system = 'metric';

    if (country === 'en' || country === 'en-ie') {
        system = 'imperial';
    }

    const UserDataSchema = Yup.object().shape({
        weight: Yup.number()
            .max('300', intl.formatMessage({id: 'form.weight.required'}))
            .required(intl.formatMessage({id: 'form.weight.required'}))
            .typeError(intl.formatMessage({id: 'form.message.isNumber'})),
        targetWeight: Yup.number()
            .max('300', intl.formatMessage({id: 'form.targetWeight.required'}))
            .required(intl.formatMessage({id: 'form.targetWeight.required'}))
            .typeError(intl.formatMessage({id: 'form.message.isNumber'})),
        bodyHeight: Yup.number()
            .required(intl.formatMessage({id: 'form.bodySize.required'}))
            .typeError(intl.formatMessage({id: 'form.message.isNumber'})),
        age: Yup.number()
            .required(intl.formatMessage({id: 'form.age.chooseAge'}))
    });

    const ageRange = createAgeRange(18, 99);

    let navigate = useNavigate();

    return (
        <Step heading={intl.formatMessage({id: 'step12.headline'})}
              description={intl.formatMessage({id: 'step12.text'})}
              currentStep={12}
              backLink={'/step11'}>
            <Formik
                initialValues={{
                    weight: '',
                    targetWeight: '',
                    bodyHeight: '',
                    age: ''
                }}
                validationSchema={UserDataSchema}
                onSubmit={(values, actions) => {
                    useStore.setState({
                        weight: values.weight,
                        targetWeight: values.targetWeight,
                        bodyHeight: values.bodyHeight,
                        age: values.age
                    });
                    navigate('../loader', {replace: true});
                }}
            >
                {(props) => (
                    <Form>
                        {system === 'metric' &&
                            <>
                                <Grid
                                    mt={5}
                                    templateColumns="repeat(2, 1fr)"
                                    gap={10}
                                    mb={5}
                                >
                                    <Box>
                                        <Field name={'weight'}>
                                            {({field, form}) => (
                                                <FormControl isInvalid={form.errors.weight && form.touched.weight}>
                                                    <FormLabel htmlFor="weight">
                                                        <FormattedMessage id="form.weight.label"/>
                                                    </FormLabel>

                                                    <InputGroup>
                                                        <Input pattern={'[0-9]*'} inputMode={'number'} {...field} id="weight"/>

                                                        <InputRightAddon pointerEvents="none">
                                                            kg
                                                        </InputRightAddon>
                                                    </InputGroup>

                                                    <FormErrorMessage>{form.errors.weight}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Box>

                                    <Box>
                                        <Field name={'targetWeight'}>
                                            {({field, form}) => (
                                                <FormControl isInvalid={form.errors.targetWeight && form.touched.targetWeight}>
                                                    <FormLabel htmlFor="targetWeight">
                                                        <FormattedMessage id="form.targetWeight.label"/>
                                                    </FormLabel>

                                                    <InputGroup>
                                                        <Input pattern={'[0-9]*'} inputMode={'number'} {...field} id="targetWeight"/>

                                                        <InputRightAddon pointerEvents="none">
                                                            kg
                                                        </InputRightAddon>
                                                    </InputGroup>

                                                    <FormErrorMessage>{form.errors.targetWeight}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Box>
                                </Grid>

                                <Box mb={5}>
                                    <Field name={'bodyHeight'}>
                                        {({field, form}) => (
                                            <FormControl isInvalid={form.errors.bodyHeight && form.touched.bodyHeight}>
                                                <FormLabel htmlFor="bodyHeight">
                                                    <FormattedMessage id="form.bodySize.label"/>
                                                </FormLabel>

                                                <InputGroup>
                                                    <Input pattern={'[0-9]*'} inputMode={'number'} {...field} id="bodyHeight"/>

                                                    <InputRightAddon pointerEvents="none">
                                                        cm
                                                    </InputRightAddon>
                                                </InputGroup>

                                                <FormErrorMessage>{form.errors.bodyHeight}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Box>
                            </>
                        }

                        {system === 'imperial' &&
                            <>
                                <Grid
                                    mt={5}
                                    templateColumns="repeat(2, 1fr)"
                                    gap={10}
                                    mb={5}
                                >
                                    <Box>
                                        <Field name={'weight'}>
                                            {({field, form}) => (
                                                <FormControl isInvalid={form.errors.weight && form.touched.weight}>
                                                    <FormLabel htmlFor="weight">
                                                        <FormattedMessage id="form.weight.label"/> (st)
                                                    </FormLabel>

                                                    <InputGroup>
                                                        <NumberInput {...field}
                                                                     onChange={(val) =>
                                                                         form.setFieldValue(field.name, val)
                                                                     }
                                                                     id="weight"
                                                                     min={6}
                                                                     max={25}
                                                                     precision={1}
                                                                     step={0.1}>
                                                            <NumberInputField />
                                                            <NumberInputStepper>
                                                                <NumberIncrementStepper />
                                                                <NumberDecrementStepper />
                                                            </NumberInputStepper>
                                                        </NumberInput>
                                                    </InputGroup>

                                                    <FormErrorMessage>{form.errors.weight}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Box>

                                    <Box>
                                        <Field name={'targetWeight'}>
                                            {({field, form}) => (
                                                <FormControl isInvalid={form.errors.targetWeight && form.touched.targetWeight}>
                                                    <FormLabel htmlFor="targetWeight">
                                                        <FormattedMessage id="form.targetWeight.label"/> (st)
                                                    </FormLabel>

                                                    <InputGroup>
                                                        <NumberInput {...field}
                                                                     onChange={(val) =>
                                                                         form.setFieldValue(field.name, val)
                                                                     }
                                                                     id="targetWeight"
                                                                     min={6}
                                                                     max={25}
                                                                     precision={1}
                                                                     step={0.1}>
                                                            <NumberInputField />
                                                            <NumberInputStepper>
                                                                <NumberIncrementStepper />
                                                                <NumberDecrementStepper />
                                                            </NumberInputStepper>
                                                        </NumberInput>
                                                    </InputGroup>

                                                    <FormErrorMessage>{form.errors.targetWeight}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Box>
                                </Grid>

                                <Box mb={5}>
                                    <Field name={'bodyHeight'}>
                                        {({field, form}) => (
                                            <FormControl isInvalid={form.errors.bodyHeight && form.touched.bodyHeight}>
                                                <FormLabel htmlFor="bodyHeight">
                                                    <FormattedMessage id="form.bodySize.label"/> (ft)
                                                </FormLabel>

                                                <InputGroup>
                                                    <NumberInput {...field}
                                                                 onChange={(val) =>
                                                                     form.setFieldValue(field.name, val)
                                                                 }
                                                                 id="bodyHeight"
                                                                 min={4}
                                                                 max={7}
                                                                 precision={1}
                                                                 step={0.1}
                                                                 width={'100%'}>
                                                        <NumberInputField />
                                                        <NumberInputStepper>
                                                            <NumberIncrementStepper />
                                                            <NumberDecrementStepper />
                                                        </NumberInputStepper>
                                                    </NumberInput>
                                                </InputGroup>

                                                <FormErrorMessage>{form.errors.bodyHeight}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Box>
                            </>
                        }

                        <Box>
                            <Field name={'age'}>
                                {({field, form}) => (
                                    <FormControl isInvalid={form.errors.age && form.touched.age}>
                                        <FormLabel htmlFor="age">
                                            <FormattedMessage id="form.age.label"/>
                                        </FormLabel>

                                        <InputGroup>
                                            <Select {...field} id="age">
                                                <FormattedMessage id="form.age.chooseAge">
                                                    {(message) => <option value="">{message}</option>}
                                                </FormattedMessage>
                                                <FormattedMessage id="form.age.under18">
                                                    {(message) => <option value="17">{message}</option>}
                                                </FormattedMessage>
                                                {ageRange.map(index => {
                                                    return (
                                                        <FormattedMessage key={'age-' + index} id="form.age.option">
                                                            {(message) => <option key={index} value={index}>{index} {message}</option>}
                                                        </FormattedMessage>);
                                                })}
                                            </Select>
                                        </InputGroup>

                                        <FormErrorMessage>{form.errors.age}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Box>

                        <Button bg="brand"
                                color={'white'}
                                size="md"
                                mt={5} w={'100%'}
                                type="submit">
                            <FormattedMessage id="form.button.next" />
                        </Button>
                    </Form>
                )}
            </Formik>
        </Step>
    );
}

export default Step12;
