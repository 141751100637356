import React from 'react'
import {Review} from "./Review";
import EnglishReviews from '../../lang/en/reviews.json';
import {Box, Text} from "@chakra-ui/react";
import {FormattedMessage} from "react-intl";

export const Reviews = (props) => {
    let reviews = EnglishReviews;

    const reviewsContent = reviews.map((review, index) => {
        return (
            <Review key={"review-" + index}
                    author={review.author}
                    date={review.date}
                    score={review.score}
                    review={review.review}/>
        )
    });

    const totalScore = reviews => {
        let totalScore = 0;

        reviews.forEach(review => {
            totalScore += review.score;
        });

        let avgRating = Math.round((totalScore / reviews.length) * 100) / 100;

        return avgRating.toFixed(1);
    }

    return (
        <>
            <Box align="center">
                <Text fontSize="3xl"><FormattedMessage id="complete.product.review.headline"/></Text>
                <Text fontSize="6xl">{totalScore(reviews)}</Text>
            </Box>

            {reviewsContent}
        </>
    )
}
